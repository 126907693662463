export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgAlternative',
  color: 'mainColorText',
  fontSize: 'md',
  borderRadius: '0',
  fontFamily: 'body',

  _focus: { borderColor: 'mainColorText' },
  _placeholder: { color: 'mainColorText' },
  borderColor: '#7a7771',
};

export const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0 0 0',
  fontFamily: 'serif',
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgAlternative',
  borderColor: '#7a7771',
  color: 'mainColorText',
  fontFamily: 'body',
  fontSize: 'md',
  fontWeight: '300',
  _focus: { borderColor: 'mainColorText' },
};
